<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">病虫害-虫情测报</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="设备名称" prop="SBMC">
                    <el-input
                        v-model="formdata.dataDic.SBMC"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="在线状态" prop="ZXZT">
                    <el-input
                        v-model="formdata.dataDic.ZXZT"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="温度" prop="WD">
                    <el-input
                        v-model="formdata.dataDic.WD"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">°C</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="湿度" prop="SD">
                    <el-input
                        v-model="formdata.dataDic.SD"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">%</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="加热仓温度" prop="JRCWD">
                    <el-input
                        v-model="formdata.dataDic.demo"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">°C</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="害虫名称" prop="HCMC">
                    <el-input
                        v-model="formdata.dataDic.HCMC"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="害虫数量" prop="HCSL">
                    <el-input
                        v-model="formdata.dataDic.HCSL"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="分布" prop="FB">
                    <el-input
                        v-model="formdata.dataDic.FB"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>

                <el-form-item label="病害面积占比" prop="BHMJZB">
                    <el-input
                        v-model="formdata.dataDic.BHMJZB"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">%</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="统计时间" prop="TJSH">
                    <el-input
                        v-model="formdata.dataDic.TJSH"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="上报时间" prop="SBSJ">
                    <el-date-picker
                        v-model="formdata.dataDic.SBSJ"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "706",
                dataDic: {
                    SBMC: "",
                    ZXZT: "",
                    WD: "",
                    SD: "",
                    JRCWD: "",
                    HCMC: "",
                    HCSL: "",
                    FB: "",
                    BHMJZB: "",
                    TJSH: "",
                    SBSJ: "",
                    SZ_SM: "",
                    SMXZ_SL: "",
                    SMXZ_SG: "",
                    SMXZ_XJ: "",
                    SMXZ_PJGF: "",
                    SMXZ_DXGF: "",
                    SMXZ_NBGF: "",
                    SZS: "",
                    QS: "",
                    GHDW: "",
                    GHNR: "",
                    SMTSZKMS: "",
                    DCR: "",
                    DCRQ: "",
                },
            },
            SGLXList: [],
        };
    },
    mounted() {
        // this.GetEnum();
    },
    methods: {
        ...mapActions(["getAllEnumInfobytableID"]),
        async GetEnum() {
            // let res = await this.getAllEnumInfobytableID({
            //     bid: "1303",
            // });
            // let enumList = res || [];
            // enumList.forEach((v) => {
            //     if (v.COLUMNID === "JB") {
            //         this.SGLXList = v.dataItems;
            //     }
            // });
        },
    },
};
</script>

<style>
.yearBox .yearItem {
    width: 48% !important;
}
.yearBox .separate {
    padding: 0 10px;
}
</style>
